/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(./fonts/Montserrat-RegularItalic.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 400;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(./fonts/Montserrat-RegularItalic.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(./fonts/Montserrat-MediumItalic.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local('Montserrat Italic'), local('Montserrat-Italic'), url(./fonts/Montserrat-MediumItalic.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(./fonts/Montserrat-BoldItalic.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), local('Montserrat-BoldItalic'), url(./fonts/Montserrat-BoldItalic.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'), url(./fonts/Montserrat-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'), url(./fonts/Montserrat-Medium.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'), url(./fonts/Montserrat-Bold.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* Open Sans Emoji */
@font-face {
  font-family: 'Open Sans Emoji';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Emoji'), local('Open-Sans-Emoji'), url(./fonts/OpenSansEmoji.ttf) format('truetype');
  unicode-range: U+0080-02AF, U+0300-03FF, U+0600-06FF, U+0C00-0C7F, U+1DC0-1DFF, U+1E00-1EFF, U+2000-209F, U+20D0-214F, U+2190-23FF, U+2460-25FF, U+2600-27EF, U+2900-29FF, U+2B00-2BFF, U+2C60-2C7F, U+2E00-2E7F, U+3000-303F, U+A490-A4CF, U+E000-F8FF, U+FE00-FE0F, U+FE30-FE4F, U+1F000-1F02F, U+1F0A0-1F0FF, U+1F100-1F64F, U+1F680-1F6FF, U+1F910-1F96B, U+1F980-1F9E0;
}

body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: sans-serif;
}
* {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  background: rgba(0, 0, 0, 0.1);
  padding: 0 0.5ch;
  opacity: 0.8;
  letter-spacing: 0.05ch;
  border-radius: 2px;
}
ol,
ul {
  padding-inline-start: 1em;
  margin-inline-start: 1em;
}
li {
  padding-inline-start: 1ch;
}
/* TODO: add first-child to in-house typography */
#root table h3:first-child {
  margin-top: 0;
}
.page-main {
  z-index: 0;
}
p {
  margin: 0 0 1em;
}
p:last-child {
  margin: 0;
}
[class*='MuiLink-button'][class*='MuiLink-button'] {
  font: inherit;
  vertical-align: baseline;
}
[class*='MuiSelect']:focus {
  background-color: initial !important;
}
form[data-testid='enduserform-form'] textarea {
  overflow: hidden;
}

.react-player {
  max-width: 100%;
  height: auto;
}
.ag-tool-panel-wrapper {
  min-width: 400px !important;
}
.ag-cell-value {
  display: inline-block;
}
.ag-theme-material.ag-theme-material {
  font-family: Montserrat, sans-serif;
}
.ag-theme-material .ag-header-cell {
  font-family: Montserrat, sans-serif;
}
.ag-theme-material .ag-cell,
.ag-theme-material .ag-header-cell {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
.ag-theme-material .ag-header {
  background-color: transparent;
  border-bottom: 0px none !important;
  color: #8c93a7 !important;
  font-family: Montserrat, sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}
.ag-header-cell:not(.ag-header-cell-sortable):hover {
  background-color: initial !important;
}
.ag-row-hover,
.ag-row-hover .ag-cell {
  background-color: #ecf0f1 !important;
}
.ag-row-focus,
.ag-row-focus .ag-cell {
  background-color: #ecf0f1 !important;
}
.ag-status-bar {
  border-bottom: 0 !important;
}
.ag-side-bar,
.ag-side-buttons {
  background: transparent !important;
  border-bottom: 0 !important;
}
.ag-side-buttons button, .ag-side-bar button {
  overflow: visible;
}
.ag-side-buttons button span, .ag-side-bar button span {
  display: -ms-flexbox;
  -ms-transform: rotate(90deg);
}
.ag-side-buttons .ag-column-select-panel {
  background: #fff;
  border-bottom: 0 !important;
}
#audit .ag-center-cols-container,
#dashboard .ag-center-cols-container,
#responses .ag-center-cols-container {
  min-width: 100%;
}
/* detect autofill with empty animation https://stackoverflow.com/a/41530164/5791449 */
input:-webkit-autofill {
  /* Expose a hook for JavaScript when auto fill is shown.
     JavaScript can capture 'animationstart' events*/
  animation-name: onAutoFillStart;
  /* Make the backgound color become yellow _really slowly_*/
  transition: background-color 50000s ease-in-out 0s;
}
@keyframes onAutoFillStart {
  from {
    /**/
  }
  to {
    /**/
  }
}
.ag-menu {
  overflow: visible !important;
}
.customInput {
  background-color: #fff;
  border: 1px solid #bfc5d1;
  border-radius: 5px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  height: 54px;
  padding: 17px;
  width: 462px;
}

.userEditDatePicker {
  padding: 5px 17px !important;
  width: 410px !important;
}
.userEditDatePicker input {
  font-size: 14px !important;
  padding: 0 !important;
}
.ag-filter-body .react-datepicker-wrapper {
  width: 100%;
}
.ag-filter-body .react-datepicker__input-container {
  width: 100%;
}
.ag-filter-body .react-datepicker__input-container input {
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  font-family: inherit;
  font-size: inherit;
  height: 40px;
  padding-bottom: 8px;
  border-width: 0;
  border-bottom: 2px solid #e2e2e2;
  padding-left: 8px;
  margin-bottom: 8px;
  width: 100%;
  box-sizing: border-box;
}
.ag-horizontal-left-spacer,
.ag-horizontal-right-spacer {
  visibility: hidden;
}
.react-datepicker-popper {
  z-index: 999999999999999999 !important;
}

.react-datepicker__portal .react-datepicker__day-name, .react-datepicker__portal .react-datepicker__day, .react-datepicker__portal .react-datepicker__time-name {
  line-height: 2rem !important;
  width: 2rem !important;
}
.one-line-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.print-view {
  margin: 0 !important;
  max-width: 210mm !important;
}
.print-view .chart-wrapper {
  max-width: 210mm !important;
  width: 210mm !important;
}
.print-view .responsive-grid-width {
  font-size: 9px !important;
}
.print-view .ag-header {
  font-size: 9px !important;
}
.print-view .ag-header-cell {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.print-view .ag-cell,
.print-view .ag-header-cell {
  border-right: 1px solid #8c93a7 !important;
}
.print-view .ag-cell {
  border-top: 1px solid #8c93a7 !important;
}
.ag-tooltip {
  background-color: #fff !important;
}
.empty-sharing-tooltip {
  display: none;
}
.ag-theme-material .ag-icon-menu {
  margin-left: 8px !important;
}
.ag-theme-material .ag-icon-menu:before {
  content: '\E914' !important;
}
.ag-theme-material .ag-header-cell-filtered .ag-icon-menu {
  color: #4c84ff !important;
}
.ag-theme-material .ag-header-cell-label .ag-header-icon.ag-filter-icon {
  display: none !important;
}
.ag-theme-material .ag-cell-label-container {
  max-width: 95% !important;
  width: initial !important;
}
.ag-filter-apply-panel button {
  background-color: #4c84ff !important;
  border-radius: 4px !important;
  color: #fff !important;
  padding: 0.5rem !important;
  text-transform: none !important;
}
/* taken from buzinas simple-scrollbar: https://github.com/buzinas/simple-scrollbar/blob/master/simple-scrollbar.css;*/
.ss-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  float: left;
}
.ss-content {
  height: 100%;
  width: calc(100% + 18px);
  padding: 0 0 0 0;
  position: relative;
  overflow: auto;
  box-sizing: border-box;
}
.ss-content.rtl {
  width: calc(100% + 18px);
  right: auto;
}
.ss-scroll {
  position: relative;
  background: rgba(0, 0, 0, 0.1);
  width: 0.9em;
  border-radius: 4px;
  top: 0;
  z-index: 2;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.25s linear;
}
.ss-hidden {
  display: none;
}
.ss-container:hover .ss-scroll,
.ss-container:active .ss-scroll {
  opacity: 1;
}
.ss-grabbed {
  -o-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
/* modifications to buzinas simple-scrollbar */
.ss-track {
  width: 0.9em;
  background-color: #f4f6f8;
  height: 100%;
  position: absolute;
  right: 0;
  opacity: 0;
}
.ss-container:active .ss-track,
.ss-container:hover .ss-track {
  opacity: 1;
}
.ss-content {
  padding-right: 0.9em;
}
.ss-content::-webkit-scrollbar {
  width: 18px;
}
[col-id='checkbox'] .ag-header-select-all.ag-hidden.show-select-all {
  display: flex !important;
}
/* .dashboard-grid {
//   display: flex; 
//   display: grid !important;
//   grid-template-columns: repeat(auto-fill, 400px);
//   grid-gap: 1rem;
//   justify-content: space-between;
// }
*/

.action-buttons-wrapper {
  padding: 0.5em !important;
  background-color: #f7f8fc;
}

::-webkit-scrollbar {
  width: 0.9em;
  height: 0.9em;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  /* background-color: #fff;*/
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  /* outline: 1px solid slategrey;*/
  border-radius: 8px;
  min-height: 40px;
  border: 3px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.6);
}

/* .dashboard-grid {
//   display: flex; 
//   display: grid !important;
//   grid-template-columns: repeat(auto-fill, 400px);
//   grid-gap: 1rem;
//   justify-content: space-between;
// }
*/

/* ==================================================================
//        _____                      _____ _      _     _
//       |  ___|__  _ __ _ __ ___   |  ___(_) ___| | __| |___
//       | |_ / _ \| '__| '_ ` _ \  | |_  | |/ _ \ |/ _` / __|
//       |  _| (_) | |  | | | | | | |  _| | |  __/ | (_| \__ \
//       |_|  \___/|_|  |_| |_| |_| |_|   |_|\___|_|\__,_|___/
// ==================================================================
*/
.ngform .EUF-base {
  overflow: hidden;
  display: none;
}

.EUF-base{
	transition: background-color 0.5s, color 0.5s;
	padding: 0 3em;
}

.EUF-admin {
  background-color: rgba(76, 132, 255, 0.1);
}

.EUF-error {
  background-color: rgba(251, 95, 126, 0.1);
  /* color: #f50057;

  // h3 {
  //    color: #f50057;
  // }
  */
}
.EUF-error  p[data-error] {
  color: #f50057;
}

.EUF-error fieldset {
  border-color: #f50057 !important;
}
.EUF-error fieldset:hover {
  border-color: #f50057 !important;
}

.EUF-view-only-admin [class*='MuiInputBase-root'], .admin-controls-view-only-admin [class*='MuiInputBase-root'] {
  background-color: #f8f8f8 !important;
  color: #000 !important;
  opacity: 1 !important;
}


.EUF-view-only-admin [class*='MuiInputBase-input'], .admin-controls-view-only-admin [class*='MuiInputBase-input'] {
  color: #000 !important;
}
